@import '../../assets/stylesheets/common/variables';

.btn {
  position: relative;
  min-height: 44px;
  padding: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: $font-weight-medium;
  &.disabled {
    @media (hover: hover) {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  &.custom {
    // all stlyes to be defined by the additionalClasses property
  }
  &.primary {
    background: $btn-primary;
    border: 1px solid $btn-primary-border;
    color: $btn-primary-text;
    letter-spacing: $letter-spacing;
    text-transform: $text-transform;
    @media (hover: hover) {
      &:hover {
        background-color: $btn-primary-hover;
        border: 1px solid $btn-primary-hover;
      }
    }
    &:active {
      background-color: $btn-primary-active;
      border: 1px solid $btn-primary-active;
    }
    &.disabled {
      background: $btn-primary-disabled;
      border: 1px solid $btn-primary-disabled;
    }
    &.pill,
    &.rounded {
      margin-right: 1px;
    }
  }
  &.primary-bright {
    @media (hover: hover) {
      &:hover {
        background-color: $brand-primary-brighter;
      }
    }
    &.disabled {
      background: $brand-primary-disabled-bright;
      border: 1px solid $brand-primary-disabled-bright;
    }
    background: $brand-primary-bright;
    border: none;
    color: $white;
    &.pill,
    &.rounded {
      margin-right: 1px;
    }
  }
  &.default {
    background: $btn-default;
    border: 1px solid $btn-default;
    color: $btn-default-text;
    letter-spacing: $letter-spacing;
    text-transform: $text-transform;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @media (hover: hover) {
      &:hover {
        background-color: $btn-default-hover;
        border: 1px solid $btn-default-hover;
      }
    }
    &:active {
      background-color: $btn-default-active;
      border: 1px solid $btn-default-active;
    }
    &.unselected {
      color: $btn-default-unselected;
    }
    &.selected {
      background: $btn-default-selected;
      border: 1px solid $btn-default-selected;
      color: $white;
    }
    &.disabled {
      background: $btn-default;
      border: 1px solid $btn-default;
      color: $btn-default-disabled;
    }
    &.pill,
    &.rounded {
      margin-right: 1px;
    }
  }
  &.secondary {
    background: $btn-secondary;
    border: 1px solid $btn-secondary-border;
    color: $btn-secondary-text;
    letter-spacing: $letter-spacing;
    text-transform: $text-transform;
    @media (hover: hover) {
      &:hover {
        background-color: $btn-secondary-hover;
        border: 1px solid $btn-secondary-hover;
      }
    }
    &:active {
      background-color: $btn-secondary-active;
      border: 1px solid $btn-secondary-active;
    }
    &.unselected {
      color: $btn-secondary-unselected;
    }
    &.selected {
      background: $btn-secondary-selected;
      border: 1px solid $btn-secondary-selected;
      color: $white;
    }
    &.disabled {
      background: $btn-secondary-disabled;
      border: 1px solid $btn-secondary-disabled;
      color: $btn-secondary-disabled-text;
    }
    &.border {
      border: 1px solid $brand-primary;
    }
    &.pill,
    &.rounded {
      margin-right: 1px;
    }
  }
  &.pill {
    padding: 8px 15px;
    border-radius: $radius-pill-btn;
  }
  &.rounded {
    padding: 8px 15px;
    border-radius: $radius-rounded-btn;
  }
  &.bold {
    font-weight: $font-weight-bold;
  }
  &.link {
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
        color: $brand-primary-hover;
      }
    }
    &:active {
      text-decoration: underline;
      color: $brand-primary-active;
    }
    background: none;
    border: none;
    box-shadow: none;
    color: $brand-primary;
    &.pill,
    &.rounded {
      margin-right: 1px;
    }
    &.selected {
      border-radius: 0;
      border-bottom: 2px solid $brand-primary;
    }
  }
  &.inlineLink {
    @extend .link;
    padding: 0px;
    min-height: 0px;
  }
}
.btn.circle {
  min-height: 30px;
  min-width: 30px;
  padding: 0px 5px;
  border-radius: 50%;
}
.btn.square {
  border-radius: 0px;
}
.btn.rounded.btn-left {
  border-radius: $radius-rounded-btn 0px 0px $radius-rounded-btn;
}
.btn.rounded.btn-right {
  border-radius: 0px $radius-rounded-btn $radius-rounded-btn 0px;
}
.btn.rounded.btn-center {
  border-radius: 0px;
}
.btn.pill.btn-left {
  border-radius: $radius-pill-btn 0px 0px $radius-pill-btn;
}
.btn.pill.btn-right {
  border-radius: 0px $radius-pill-btn $radius-pill-btn 0px;
}
.btn.pill.btn-center {
  border-radius: 0px;
}
.badge {
  position: absolute;
  top: -7px;
  left: -7px;
  height: 25px;
  width: 25px;
  padding: 0;
  border-radius: 50%;
  background-color: $red;
  color: $white;
  line-height: 25px;
}
.hidden {
  display: none;
}
.button-icon-red {
  color: $red;
}
.button-icon svg {
  vertical-align: middle;
  margin-bottom: 2px;
}
