/* Migrated to React */
@import '@/assets/stylesheets/common/screens';
@import '@/assets/stylesheets/common/variables';

.contentPane {
  display: flex;
  flex-direction: column;
  font-family: $source_sans_pro;
  font-size: $font-size-normal;
  min-height: 100vh;
}

.pageHeader {
  background-color: $white;
  padding-top: $spacing-2;
  border-bottom: 1px solid $grey-light;
}

.bodyContainer {
  flex: 1 1;
  padding-top: $spacing-4;
  padding-bottom: $spacing-4;
  background-color: $grey-light;
}

.titleContainer {
  background-color: $white;
}
