.link {
  font-size: $font-size-normal;
  line-height: 1.1;
  font-weight: $font-weight-normal;
  color: $link;
  text-decoration: none;

  &:hover {
    color: $link;
    text-decoration: underline;
  }

  &:focus {
    color: $link-focus;
    text-decoration: underline;
    
  }

  &:visited {
    color: $link-visited;
    text-decoration: underline;
  }
}