@import '../assets/stylesheets/common/variables';

.toastContainer {
  position: fixed !important;
  top: $spacing-2;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;

  .toastInnerContainer {
    border-radius: $radius-toast-message;
  }

  .toastBody {
    border-radius: $radius-toast-message;
    padding: $spacing-2 $spacing-4;
    font-weight: $font-weight-normal;

    &::before {
      font-family: inherit;
      font-size: $spacing-3 + 1;
      display: inline-block;
      padding-right: $spacing-2 - 2;
      vertical-align: middle;
      font-weight: $font-weight-bold;
    }
  }

  .success {
    background-color: $toast-success;

    &::before {
      content: '\2713';
      color: $green;
    }
  }

  .error {
    background-color: $toast-error;

    &::before {
      content: '\2717';
      color: $red;
    }
  }

  .info {
    background-color: $toast-info;
  }
}
